.portfolio-container {
    font-size: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100vh;

}

.portfolio-cont-1 {
    position: relative;
    width: 100%;
    max-height: 90vh;
}

.bringing-port {
    margin-top: 10rem;
    font-size: 64px;

}

.blueball-image {
    min-width: 40vw;
    max-width: 50%;
    height: auto;
    object-fit: contain;

}

.portfolio-container-main {
    height: 100%;
    width: 100%;
    background-color: black;
}

.portfolio-text {
    z-index: 11;
}

.flower-image {
    max-width: 200px;
}

.footer-portfolio {
    margin-top: 10rem;

    .fold-7 {
        margin-bottom: 5rem;

        .lets-build {
            font-size: 50px;
            text-align: center;
            margin-bottom: 4rem;
        }

        .form-container {
            display: flex;
            justify-content: space-around;

            .flower-image {
                // max-height: 400px;

                max-width: 300px;
            }

            div>form>.input_container {
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                // gap: 4rem;
                input {
                    height: 50px;
                    width: 500px;
                    outline: 1px solid #ecf7cd;
                    border-radius: 8px;
                    background-color: black;
                    padding: 1rem;
                }

                .form-button {
                    max-width: 300px;
                    height: 70px;
                    font-weight: 700;
                    font-family: Manrope;
                    font-size: 20px;
                    background-color: #a1d905;
                    border-radius: 8px;
                    color: black;
                }
            }
        }
    }

    .fold-8 {

        display: flex;
        flex-direction: column;
        gap: 5rem;

        .footer-1 {
            display: flex;
            flex-direction: row;
            // justify-content: space-around;
            justify-content: center;
            gap: 4rem;
            align-items: center;
            text-align: center;
        }

        .footer-2 {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            text-align: center;
            margin-bottom: 4rem;

            .social {
                display: flex;
                gap: 1rem;
            }
        }

        // margin: -1rem;
    }

    .bringing-port {
        // font-size: 36px;
        text-align: center;
    }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 768px) {

    /* Styles */
    .portfolio-container {
        height: 400px;
    }

    .blueball-image {
        top: 0;
        // right: 40%;
        min-width: 250px;

    }

    .products-why {

        flex-direction: column;
    }

    .bringing-port {
        font-size: 36px;
        text-align: center;
    }

    .research-why {
        display: none;
    }

    .prods-by-why {
        display: flex;

        .research-why {
            display: none;


        }
    }

    .portfolio-icon {
        width: 20px;
        height: 20px;
    }

    .projects-why-name {
        font-size: 24px;
    }

    .project-details {
        font-size: 14px;
    }

    .flower-image {
        bottom: 10px;
    }

    .fold-7 {
        display: none;
    }

    .fold-8 {

        display: flex;
        flex-direction: column;
        gap: 1rem !important;
    }

    .footer-1 {
        margin-top: 2rem;
        display: flex;
        flex-direction: column !important;
        color: #272d38;
        font-family: Manrope;
        gap: 1rem !important;
    }

    .footer-2 {
        display: flex;
        flex-direction: column;
        color: #272d38;
        font-family: Manrope;
        margin-bottom: 4rem;
    }

    .homepage-container .fold-8 .footer-2 {
        gap: 1rem;
    }

    .footer-2 {
        margin-bottom: 4rem;
        // 4rem
    }

    .footer-2 {
        flex-direction: column !important
    }

    .bringing-port {
        // font-size: 32px !important;
    }

    .prod-image {
        // min-width: 300px;
        // min-height: 300px;
        // align-items: center;

    }

    .bring-container {
        padding: 0 2rem;


    }

    .project-details .projects-why-name .portfolio-button {
        margin: 1rem;
    }

    .projects-why-name {
        margin: 2rem;
    }

}