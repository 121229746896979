.case-study-button {
	width: 209.81px;
	height: 63.9px;
	background: #f6fafe;
	border-radius: 58.1759px;
	color: #282d46;
}

.homepage-button {
	max-width: 300px;
	min-width: 300px;
	max-height: 69.98px;
	min-height: 69.98px;
	background-color: #a1d905;
	color: #111111;
	border-radius: 8px;
	font-size: 20px;
	font-weight: 800;
}

@media only screen and (max-width: 600px) {
	.case-study-button {
		margin: 0;
		padding: 12px;
		max-width: 120px;
		height: 42.9px;
		display: flex;
		justify-content: center;
		align-items: center;

		font-size: 12px;
		background: #f6fafe;
		border-radius: 58.1759px;
		color: #282d46;
	}

	.homepage-button {
		min-width: 160px;
		min-height: 50.98px;
		background-color: #a1d905;
		color: #111111;
		border-radius: 8px;
		font-size: 14px;
		font-weight: 800;
	}
}

/* Ripple effect */
.ripple {
	background-position: center;
	transition: background 0.8s;
}

.homepage-button:hover {
	background: #e0f2ac radial-gradient(circle, transparent 1%, #a1d905 1%) center/15000%;
}

.homepage-button:active {
	background-color: #a1d905;
	background-size: 100%;
	transition: background 0s;
}

.about-us-mobile:hover {}


.portfolio-button {
	min-width: 120px;
	min-height: 50.98px;
	background-color: #a1d905;
	color: #111111;
	border-radius: 8px;
	font-size: 14px;
	font-weight: 800;
}