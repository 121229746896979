#app {
	height: 100%;
}

html,
body {
	position: relative;
	height: 100%;
}

body {
	// background: #eee;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 14px;
	color: #000;
	margin: 0;
	padding: 0;
}

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: rgb(0, 0, 0);
	border-radius: 12px;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
	max-width: 400px;
}

.image-1-carousel {
	// background-color: #4a9fcc;
}

.image-2-carousel {
	// background-color: #fc4b58;
}

.image-1-carousel {
	// background-color: #fff;
}