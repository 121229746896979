.image-about-us {
    width: 400px;
    height: 400px;
}


@media only screen and (max-width: 600px) {



    .About-us {
        text-align: left;
        margin-left: 2rem;
    }

    .fun-space-container {
        margin: 0;
        margin-right: 2rem;
        padding: 0;
    }

    .fun-space {
        text-align: left;
        margin-left: 2rem;
    }

    .our-work {
        text-align: left;
        margin-left: 2rem;

    }

    .our-team-about-us {
        padding: 0;
        margin-left: 2rem;
        text-align: left;
    }

    .c-text-m {
        // flex-direction: column;
        display: flex;
        justify-content: flex-start;
        margin-left: 2rem;
        flex-wrap: wrap;
        text-align: left;
    }

    .image-about-us {
        width: 200px;
        height: 200px;


    }

    .l-k-brilliant {
        text-align: left;
        margin-left: -3rem;
        margin-right: -3rem;
    }
}

.input-form-contact {
    outline-color: #FFF;
    background-color: #000;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 0.5rem;
    gap: 1rem;
}

.form-cont {
    background-color: #000;
}

.about-us-icons {
    margin-left: 2rem;

}

.contact-cont {
    background-image: url("./../../assets/about-us/overlay\ \(1\).svg");
    background-repeat: no-repeat;
}