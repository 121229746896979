@font-face {
	font-family: 'Poppins';
	src: url(./../../assets//fonts/Poppins-Regular.otf);
}

.poppins-med {
	font-family: 'Poppins';
}

@font-face {
	font-family: 'Manrope';
	src: url(./../../assets/fonts/manrope-regular.otf);
}

@font-face {
	font-family: 'Manrope-Bold';
	src: url(./../../assets/fonts/manrope-bold.otf);
}

.poppins-med {
	font-family: 'Poppins';
}

.manrope-bold {
	font-family: 'Manrope-Bold';
}

.manrope {
	font-family: 'Manrope';
}
