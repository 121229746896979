@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Merriweather&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

html {
	height: 100vh;
	scroll-behavior: smooth;
}

.form-text-area {
	background-color: black;
	border: 1px solid #ecf7cd;
	border-radius: 8px;
	padding: 1rem;
	resize: none;
}
