/*======================
    404 page
=======================*/

.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: 'Arvo', serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page_404 img {
    width: 100%;
}

.text-center {
    text-align: center;
    font-family: Avenir;
}

.four_zero_four_bg {
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    // display: flex;
    gap: 2rem;
    justify-content: center;
    background-position: center;
}

.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    padding: 15px 30px;
    display: flex;
    background: #39ac31;
    margin: 20px 0;
    border-radius: 8px;
    display: inline-block;
}

.contant_box_404 {
    display: flex;
    flex-direction: column;
    margin-top: -50px;
    align-items: center;
}

.pageNotFound {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    margin: 0;
}

.takeMeHome {
    margin-top: 2rem;
    width: 214px;
    height: 46px;
    border-radius: 26px;
    border: none;
    // background: linear-gradient(279deg, #af098c 10%, #6930b7 100%);
    font-family: Avenir-Heavy;
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.03px;
    outline: none;
    color: #ffffff;
    color: #000;
    border: 1px solid black;
    border-radius: 8px;
}

.contact_us {
    font-family: Avenir-Heavy;
    // margin:50px 0;
    padding-top: 20px;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #881098;
}

.page_not_found {
    font-family: Avenir-Heavy;
    // padding-bottom: 50px;
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
}