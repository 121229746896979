.case-study-text-content {
}

.problem-statement-container {
	background-color: rgba(254, 255, 156, 0.8);
	.pin-icon {
		top: -20px;
		left: 20px;
	}
}

.objective-container {
	ul {
		list-style: disc;
	}
}

.visual-text {
	width: 70%;
}

.visual-content-container {
	border: 2px solid rgb(221, 221, 221);
	padding: 0.5rem;
	border-radius: 4px;
}

.learning-container {
	background-color: #eaf4fd;
}

.thanks {
	background: linear-gradient(
		107.66deg,
		#d7e4e3 -15.67%,
		#ffffff 42.89%,
		#d7e4e3 100%
	);
}

.architecture-image {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 600px;
	max-width: auto;
}

.thanks {
	display: flex;
	justify-content: center;
	align-items: center;
}

.p-width {
	max-width: 250px;
}
.architecture-image-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.our-process-image {
	min-width: 200px;
	max-width: 250px;
	height: auto;
	gap: 2rem;
}
@media only screen and (max-width: 600px) {
	.bitfire-case-study-container {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		overflow-x: hidden;
		.bitfire-case-study-content-container {
			margin: 0;
			padding: 0;
			.case-study-buttons-container {
				margin: 0;
				padding: 0 2rem;
			}
		}
		.heading-1 {
			padding: 2rem 2rem;
			font-size: 32px;
		}
		.text-1 {
			padding: 2rem 2rem;
		}
		.image-hero {
			margin-left: auto;
			margin-right: auto;
			padding: 2rem;
		}
		.problem-statement-container {
			margin: 0;
			padding: 2rem;

			width: 90%;
			margin-left: auto;
			margin-right: auto;
			.pin-icon {
				left: 2px;
				top: -20px;
			}
			.problem-statement-heading {
				font-size: 20px;
			}
		}
		.solution-container {
			margin: 0;
			padding: 2rem;
			.solution-heading {
				font-size: 20px;
			}
		}
		.marketing-container {
			margin: 0;
			padding: 2rem;
			.marketing-heading {
				font-size: 20px;
			}
		}
		.objective-container {
			margin: 0;
			padding: 2rem;
			ul {
				padding: 2rem;
				flex-direction: column;
				gap: 1rem;
			}
			.objective-heading {
				font-size: 20px;
			}
		}
		.process-container {
			margin: 0;
			padding: 2rem;
			.process-heading {
				font-size: 20px;
			}
			.process-image-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.our-process-image {
					min-width: 80px;
					max-width: 150px;
					height: auto;
					gap: 2rem;
				}
			}
		}
		.features-container {
			.feature-image-container {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.feature-image {
					min-width: 80px;
					max-width: 150px;
					height: auto;
					gap: 2rem;
				}
			}
		}
		.business-container {
			margin: 0;
			padding: 2rem;
			.business-heading {
				font-size: 20px;
			}
			div {
				p {
					gap: 1rem;
				}
			}
		}
		.quantitative-container {
			margin: 0;
			padding: 2rem;

			.quantitative-heading {
				font-size: 20px;
			}
			.observation-heading {
				font-size: 16px;
			}
		}
		.product-user-container {
			div > p {
				gap: 1rem;
			}
		}
		.competitor-container-1 {
			div > p {
				gap: 1rem;
			}
			.red-img {
				// display: none;
			}
			.coinmarketimg {
				// display: none;
			}
			.competitor-sub-heading {
				font-size: 16px;
			}
		}
		.competitor-container-2 {
		}

		.design-user-container {
			div > .design-text-p {
				gap: 1rem;
			}
		}
		.architecture-user-container {
			.wireframe-sub-heading {
				font-size: 16px;
			}
			.wireframe-images {
				margin-top: 40px;
				display: flex;
				flex-direction: column;
				gap: 2rem;
				img {
					display: flex;
					justify-content: center;
					align-items: center;
					max-width: 300px;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
		.visual-container {
			margin-top: 0;
			.visual-heading {
				margin: 0;
				margin-bottom: 0;
			}
			.visual-content-container {
				display: flex;
				flex-direction: column; // justify-content: ;
				margin: 0;
				padding: 0;
				.visual-sub-heading {
					margin: 0;
					font-size: 16px;
				}
				.visual-content-sub {
					margin: 0;
					padding: 0;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.visual-images {
					width: 200px;
					height: 200px;
				}
			}
			.visual-content-container-middle {
				display: flex;
				flex-direction: column-reverse;
				margin: 0;
				padding: 0;
				.visual-content-sub {
					margin: 0;
					padding: 0;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.visual-sub-heading {
					margin: 0;
					font-size: 16px;
				}
				.visual-images {
					width: 200px;
					height: 200px;
				}
			}
			.visual-content-container {
				border: none;
			}
		}
		.userneeds-container {
			.user-need-text {
				gap: 1rem;
			}
		}
	}
	.architecture-image-container {
		display: flex;
		justify-content: center;
		align-items: center;
		.architecture-image {
			max-width: 80vw;
			max-height: auto;
		}
	}

	.heading-main {
		font-size: 20px;
	}
	.container-sub {
		margin: 0;
		padding: 2rem;
	}
	.text-heading-yellow {
		font-size: 20px;
	}
	.observation-info {
		margin: 0px;
	}
}
