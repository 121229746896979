.scroll-indicator {
	background-color: #282d06;
}

.scroll-indicator-2 {
	background-color: #a1d905;
}

.hamburger {
	display: none;
}

.ham-item {
	font-size: 1.5rem;
	display: flex;
	padding: 1rem;
	font-family: 'Poppins';
}

.header-white>p:hover {
	color: #a1d905;
	transition: 0.5s ease;
}

@media only screen and (max-width: 600px) {
	.header {
		padding: 2rem;
		margin: 0;
	}

	.nav-items {
		display: none;
	}

	.hamburger {
		display: block;
	}
}

.nav-items {
	p {
		cursor: pointer;
	}
}