.fold-1-2 {
	margin-right: 5rem;
	margin-left: 5rem;
}

.homepage-container {
	height: 100%;
	width: 100%;
	background-color: black;

	.first-fold {
		height: 100vh;
		display: flex;
		position: relative;

		.homepage-hero-text-container {
			width: 40%;
			max-width: 600px;
			display: flex;
			flex-direction: column;
			margin-top: 4rem;

			p {
				z-index: 111111;
			}

			.homepage-hero-text {
				font-size: 60px;
				// z-index: 11111111111;
			}

			.homepage-hero-text-2 {
				font-size: 20px;
			}

			position: relative;
		}

		.homepage-hero-image {
			max-width: 575px;
			max-height: 631px;
			min-width: 250px;
			min-height: auto;
			z-index: 11111;
			margin: 0 -5rem;
		}

		.wave-image {
			position: absolute;
			min-width: 300px;
			min-width: 100%;
			top: 300px;
			position: absolute;
			margin-left: auto;
			margin-right: auto;
			left: 0;
			right: 0;
			text-align: center;
		}
	}

	.asking-why-container {
		// padding: 0 1rem;
		// margin: 01rem;

		width: 100%;

		.asking-why-text-container {
			.text {
				font-size: 40px;
			}

			.asking-why-container-1 {
				width: 70%;
			}

			.asking-why-container-2 {
				margin-top: 1rem;
				width: 30%;
			}
		}

		.text-bottom-container {
			min-width: 100%;
			padding: 1rem;
			display: flex;
			justify-content: space-around;
			align-items: center;
			min-height: 150px;
			bottom: 0;
			background-color: rgba(248, 248, 248, 0.1);

			p {
				display: flex;
				gap: 1rem;
				align-items: center;
			}

			.about-use-fold-2 {
				border: 2px solid #a1d905;
				padding: 1rem;
				border-radius: 8px;
				font-weight: 600;
			}

			.about-us-button:hover {
				color: #a1d905;
				transition: 0.3s ease;
			}

			.box-2 {
				display: flex;
				text-align: center;
				width: 250px;
				height: 300px;
				border: 1px solid white;
			}
		}
	}

	.fold-3 {
		display: flex;

		//
		//
		.what-do-we-do-container {
			height: 100%;
			width: 100%;

			.what-do-we-do-1 {
				font-size: 48px;
				font-family: 'Manrope';
			}

			.box-containers {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-around;
				margin: 2rem;
				font-family: Manrope;
				font-weight: 600;
				size: 32px;
				font-size: 20px;

				div {
					border-radius: 8px;
				}

				.box-1 {
					display: flex;
					align-items: center;
					width: 200px;
					height: 143px;
					border: 1px solid linear-gradient(279deg, #af098c 10%, #6930b7);
					margin: 1rem;
					justify-content: flex-start;
					padding: 1rem;
					color: #c0e658;
					border-left: 4px solid rgba(161, 217, 5, 0.25);
					border-right: 4px solid rgba(161, 217, 5, 0.45);
					border-top: 4px solid rgba(255, 255, 255, 0.25);
					border-bottom: 4px solid rgba(253, 233, 233, 0.25);
				}

				.box-2 {
					display: flex;

					align-items: center;

					width: 250px;
					height: 200px;
					border: 4px solid rgba(255, 255, 255, 0.25);
					margin: 1rem;
					justify-content: flex-start;
					padding: 1rem;
					border-left: 4px solid rgba(161, 217, 5, 0.25);
					border-right: 4px solid rgba(161, 217, 5, 0.45);
					border-top: 4px solid rgba(255, 255, 255, 0.25);
					border-bottom: 4px solid rgba(253, 233, 233, 0.25);
				}

				.box-3 {
					display: flex;
					text-align: center;
					justify-content: center;
					align-items: center;
					text-align: center;
					border-left: 4px solid rgba(161, 217, 5, 0.25);
					border-right: 4px solid rgba(161, 217, 5, 0.45);
					border-top: 4px solid rgba(255, 255, 255, 0.25);
					border-bottom: 4px solid rgba(253, 233, 233, 0.25);

					width: 200px;
					height: 180px;
					// border: 4px solid rgba(255, 255, 255, 0.25);
					margin: 1rem;
					justify-content: flex-start;
					padding: 1rem;
				}

				.box-4 {
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: center;
					justify-content: center;
					align-items: center;
					text-align: center;
					justify-content: flex-start;
					padding: 1rem;
					width: 200px;
					height: 180px;
					border: 4px solid rgba(255, 255, 255, 0.25);
					margin: 1rem;
					border-right: 4px solid rgba(161, 217, 5, 0.25);
					border-left: 4px solid rgba(161, 217, 5, 0.45);
					border-bottom: 4px solid rgba(255, 255, 255, 0.25);
					border-top: 4px solid rgba(253, 233, 233, 0.25);
				}

				.box-5 {
					display: flex;
					color: #c0e658;
					width: 150px;
					height: 100px;
					border: 4px solid rgba(255, 255, 255, 0.25);
					text-align: center;
					justify-content: center;
					align-items: center;
					justify-content: center;
					align-items: center;
					text-align: center;
					justify-content: flex-start;
					padding: 1rem;
					border-right: 4px solid rgba(161, 217, 5, 0.25);
					border-top: 4px solid rgba(161, 217, 5, 0.45);
					border-left: 4px solid rgba(255, 255, 255, 0.25);
					border-bottom: 4px solid rgba(253, 233, 233, 0.25);

					margin: 1rem;
				}

				.box-6 {
					display: flex;

					width: 300px;
					height: 100px;
					border: 4px solid rgba(255, 255, 255, 0.25);
					justify-content: center;
					align-items: center;
					justify-content: flex-start;
					padding: 1rem;
					margin: 1rem;
					border-left: 4px solid rgba(161, 217, 5, 0.25);
					border-bottom: 4px solid rgba(161, 217, 5, 0.25);
					border-top: 4px solid rgba(255, 255, 255, 0.25);
					border-right: 4px solid rgba(253, 233, 233, 0.25);
				}

				.box-7 {
					display: flex;
					justify-content: flex-start;
					padding: 1rem;
					width: 300px;
					height: 150px;
					border: 4px solid rgba(255, 255, 255, 0.25);
					margin: 1rem;
					color: #c0e658;
					align-items: center;
					// border-image: linear-gradient(
					// 	90deg,
					// 	rgba(192, 230, 88, 1) 23%,
					// 	rgba(255, 255, 255, 1) 78%
					border-bottom: 4px solid rgba(161, 217, 5, 0.25);
					border-right: 4px solid rgba(161, 217, 5, 0.15);
					border-top: 4px solid rgba(255, 255, 255, 0.25);
					border-left: 4px solid rgba(253, 233, 233, 0.25);
					// );
				}

				.box-8 {
					display: flex;

					width: 200px;
					height: 150px;
					border: 4px solid rgba(255, 255, 255, 0.25);
					justify-content: flex-start;
					padding: 1rem;
					align-items: center;
					margin: 1rem;
					border-left: 4px solid rgba(161, 217, 5, 0.25);
					border-top: 4px solid rgba(161, 217, 5, 0.05);
					border-right: 4px solid rgba(255, 255, 255, 0.25);
					border-bottom: 4px solid rgba(253, 233, 233, 0.25);
				}

				.box-9 {
					display: flex;
					text-align: center;
					width: 200px;
					height: 70px;

					justify-content: center;
					align-items: center;
					text-align: center;

					align-items: center;
					margin: 1rem;
					border-radius: 8px;
					background-color: #a1d905;
				}
			}
		}
	}

	.fold-4 {
		max-height: 500px;

		//
		.our-projects {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 5rem;
			font-size: 60px;

			.our-projects-container-1 {
				display: flex;
				flex-direction: column;
				gap: 3rem;
				width: 100%;
			}
		}
	}

	.fold-5 {
		//
		//
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: inherit;
		text-align: center;
		gap: 3rem;

		.fold-5-container-1 {
			gap: 3rem;
		}

		.building-relationship {
			font-size: 68px;
		}

		.alongwith {
			font-size: 24px;
			text-align: center;
		}

		.logo-container-homepage {
			display: flex;
			justify-content: center;
			flex-direction: row;
			align-items: center;
			flex-wrap: wrap;

			img {
				max-width: 200px;
				object-fit: cover;
				max-height: 200px;
				margin: 2rem;
				filter: grayscale(100%);
			}
		}
	}

	.fold-6 {
		margin: 0 -5rem;
		background: linear-gradient(140.63deg,
				#8d0b93 6.98%,
				#8d0b93 7.04%,
				#7524ac 84.18%,
				#2575fc 154.33%);

		.from-why-labs {
			margin: 8rem;
			display: flex;
			justify-content: space-around;
			padding: 10rem 0;
		}

		.from-why-heading {
			font-size: 68px;
		}

		.from-why-text-2 {
			font-size: 24px;
		}

		.klose-box-container {
			flex-direction: row;
			display: flex;
			gap: 1rem;
		}

		.klose-sign-up {
			width: 164px;
			height: 100px;
			border: 1px solid #fffffe;
			border-radius: 8px;
			margin: 2rem 0;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			flex-direction: column;
		}

		.explore-klose {
			width: 300px;
			height: 70px;
			background-color: white;
			color: #8c0c94;
			border-radius: 8px;
		}

		.img-container-klose {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.fold-7 {
		margin-bottom: 5rem;

		.lets-build {
			font-size: 50px;
			text-align: center;
			margin-bottom: 4rem;
		}

		.form-container {
			display: flex;
			justify-content: space-around;

			.flower-image {
				// max-height: 400px;

				max-width: 300px;
			}

			div>form>.input_container {
				display: flex;
				flex-direction: column;
				justify-content: space-around;

				// gap: 4rem;
				input {
					height: 50px;
					width: 500px;
					outline: 1px solid #ecf7cd;
					border-radius: 8px;
					background-color: black;
					padding: 1rem;
				}

				.form-button {
					max-width: 300px;
					height: 70px;
					font-weight: 700;
					font-family: Manrope;
					font-size: 20px;
					background-color: #a1d905;
					border-radius: 8px;
					color: black;
				}
			}
		}
	}

	.fold-8 {

		display: flex;
		flex-direction: column;
		gap: 5rem;

		.footer-1 {
			display: flex;
			flex-direction: row;
			// justify-content: space-around;
			justify-content: center;
			gap: 4rem;
			align-items: center;
			text-align: center;
		}

		.footer-2 {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			text-align: center;
			margin-bottom: 4rem;

			.social {
				display: flex;
				gap: 1rem;
			}
		}

		// margin: -1rem;
	}

	.homepage-hero-text-container {
		margin: 1rem;
	}

	.fold-2 {
		padding: 1rem -1px;
	}

	.fold-3,
	.fold-4,
	.fold-5 {
		margin: 1rem;
	}

	.about-use-fold-2 {
		font-size: 12px;
	}

	.about-us-mobile {
		// margin-top: 2rem;
		// margin-left: auto;
		font-size: 12px;
		width: 140px;
		height: 70px;
		text-align: center;
		justify-content: center;
		font-size: 12px;
		align-items: center;
		background-position: center;
		transition: background 0.8s;
	}

	.about-us-mobile:hover {
		background: #000 radial-gradient(circle, transparent 1%, #a1d905 1%) center/15000%;
	}

	.about-us-mobile:active {
		// background-color: #a1d905;
		background-size: 100%;
		transition: background 0s;
	}

	.mobile-about-us-btn-2 {
		color: #a1d905;
		border: 2px solid #a1d905;
		height: 48px;
		font-size: 12px;
		max-width: 160px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		z-index: 1111;
		gap: 1rem;
	}

	.mobile-about-us-btn-2:active {
		background-size: 100%;
		transition: background 0s;
	}

	.mobile-about-us-btn-2:hover {
		background: rgb(104, 104, 104) radial-gradient(circle, transparent 1%, #a1d905 1%) center/15000%;
	}
}

@media only screen and (min-device-width: 720px) and (max-device-width: 1024px) {

	.homepage-container {
		height: 100%;
		width: 100%;
		background-color: black;

		.first-fold {
			.homepage-hero-text-container {
				width: 40%;
				max-width: 600px;
				display: flex;
				flex-direction: column;
				margin-top: 4rem;

				p {
					z-index: 111111;
				}

				.homepage-hero-text {
					font-size: 32px;
				}

				.homepage-hero-text-2 {
					font-size: 16px;
				}

				position: relative;
			}

			.homepage-hero-image {
				max-width: 375px;
				max-height: 400px;
				min-width: 250px;
				min-height: auto;
				z-index: 11111;
			}
		}

		.logo-Mobile {
			display: none;
		}

		.get-in-touch {
			display: none;
		}
	}

	.lets-build-phone {
		display: none;
	}
}

@media screen and (min-width: 1024px) {

	/* Laptop */
	.asking-why-2 {
		display: none;
	}

	.logo-Mobile {
		display: none;
	}

	.explore-klose-2 {
		display: none;
	}

	.get-in-touch-container {
		display: none;
	}

	.first-fold-mobile,
	.fold-3-mobile {
		display: none;
	}

	.we-believe-in-letting {
		font-size: 24px;
	}

	.social-2 {
		display: none;
	}

	.about-us-btn-mobile {
		display: none;
	}

	.lets-build-phone {
		display: none !important;

	}

}

@media screen and (min-width: 700px) {

	/* Laptop */
	.asking-why-2 {
		display: none;
	}

	.logo-Mobile {
		display: none;
	}

	.explore-klose-2 {
		display: none;
	}

	.get-in-touch-container {
		display: none;
	}

	.first-fold-mobile,
	.fold-3-mobile {
		display: none;
	}

	.we-believe-in-letting {
		font-size: 24px;
	}

	.social-2 {
		display: none;
	}

	.carousel-phone-1 {
		display: none;
	}

	.about-us-btn-mobile {
		display: none;
	}
}

@media screen and (min-width: 800px) {

	/* Laptop */
	.asking-why-2 {
		display: none;
	}

	.logo-Mobile {
		display: none;
	}

	.explore-klose-2 {
		display: none;
	}

	.get-in-touch-container {
		display: none;
	}

	.first-fold-mobile,
	.fold-3-mobile {
		display: none;
	}

	.we-believe-in-letting {
		font-size: 24px;
	}

	.social-2 {
		display: none;
	}

	.carousel-phone-1 {
		display: none;
	}

	.about-us-btn-mobile {
		display: none;
	}
}

@media only screen and (max-width: 600px) {
	body {
		// background-color: black;
	}

	.homepage-hero-text-container {
		padding-left: 1rem;
	}

	.fold-1-2 {
		width: 100%;
		margin: 0 0;

		//
	}

	.what-do-we-do-image {
		display: none;
	}

	.homepage-container {
		height: 100%;
		width: 100%;
		background-color: black;
		margin: 0;
		padding: 0;
		position: static;

		.first-fold {
			height: 100vh;
			width: 100%;
			display: flex;
			flex-direction: column;
			position: static;

			.homepage-hero-text-container {
				position: static;

				p {}

				.homepage-hero-text {
					font-size: 36px;
					// z-index: 11111111111;
				}

				.homepage-hero-text-2 {
					font-size: 16px;
				}

				// position: relative;
			}

			.homepage-hero-image {
				max-width: 300px;
				max-height: 334px;
				min-height: 250px;
				min-width: 250px;
				// display: none;
			}
		}
	}

	.homepage-container .first-fold .wave-image {
		display: none;
	}

	.homepage-container .asking-why-container .asking-why-text-container .text {
		margin-top: 5rem;
		font-size: 20px;
	}

	.homepage-container .asking-why-container {
		position: static;
		display: flex;
		flex-direction: column;
	}

	.homepage-container .asking-why-container .text-bottom-container {
		position: static;
		display: flex;
		flex-direction: column;
		// display: none;
		gap: 2rem;
		background-color: black;
	}

	.homepage-container .asking-why-container .text-bottom-container p {
		font-size: 16px;
	}

	.homepage-container .asking-why-container .asking-why-text-container .asking-why-container-2 {
		position: absolute;
		height: 70%;
		justify-content: center;
		width: 90%;
		z-index: 1;
		// opacity: 0.6;
		padding-left: 1rem;

	}

	.homepage-container .asking-why-container .text-bottom-container {
		align-items: flex-start;
	}

	.homepage-container .asking-why-container .text-bottom-container img {
		width: 25px;
		height: auto;
	}

	.icons-help {
		min-width: 30px;
		max-width: 30px;
	}

	.icons-help-2 {
		min-width: 30px;
		max-width: 30px;
	}

	.homepage-container .asking-why-container .text-bottom-container p {
		z-index: 111;
		font-size: 16px;
	}

	.what-do-we-do {
		font-size: 32px;
	}

	.our-projects {
		display: block;
	}

	.our-projects-container-1 {
		display: block;
	}

	.homepage-container .fold-4 .our-projects {
		display: block;
	}

	.homepage-container .fold-5 .building-relationship {
		// margin-top: 50rem;
		font-size: 32px;
	}

	.our-latest-pro {
		font-size: 31px;
	}

	.homepage-container .fold-5 .alongwith {
		font-size: 16px;
	}

	.homepage-container .fold-6 .from-why-heading {
		font-size: 31px;
	}

	.homepage-container .fold-6 .from-why-text-2 {
		font-size: 16px;
	}

	.homepage-container .fold-6 .from-why-labs {
		display: block;
		padding: 0;
	}

	.homepage-container .fold-6 .explore-klose {
		width: 120px;
		height: 42px;
		font-size: 12px;
	}

	.homepage-container .fold-4 .our-projects {
		display: block;
	}

	.homepage-container .fold-4 .our-projects .our-projects-container-1 {
		gap: 0;
		display: block;
	}

	.homepage-container .fold-5 .building-relationship {
		// margin-top: 10rem;
		// padding: 15rem;
	}

	.homepage-container .fold-6 {
		// margin-top: 13rem;
	}

	.homepage-container .fold-7 .lets-build {
		font-size: 31px;
	}

	.homepage-container .fold-7 .form-container .flower-image {
		display: none;
	}

	.homepage-container .fold-7 .form-container div>form>.input_container {
		display: none;
	}

	.homepage-container .fold-6 {
		margin: 0 0;
	}

	.from-why-labs {

		// display: flex;
		.from-why-heading {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.from-why-text-2 {
			width: 100%;
			display: flex;
			text-align: center;
			justify-content: center;
			align-items: center;
		}

		.klose-box-container {
			display: flex;
			justify-content: center;
		}

		.klose-sign-up {
			display: flex;
			justify-content: center;
			align-items: center;
			max-width: 110px;
			max-height: 80px;
			font-size: 10px;
		}

		.klose-demo-image {
			max-width: 350px;
			max-height: 350px;
			// margin-bottom: 3rem;
		}

		.explore-klose {
			display: none;
		}

		.img-container-klose {
			display: flex;
			flex-direction: column;
		}

		.explore-klose-2 {
			width: 160px;
			height: 50px;
			background-color: #ffffff;
			color: #7524ac;
			border-radius: 8px;
			font-size: 14px;
		}

		.fold-7 {
			margin-top: 5rem;
		}
	}

	.get-in-touch-container {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 12px;
	}

	.fold-8 {
		background-color: #fffffe;
		display: flex;
		flex-direction: column;
	}

	.homepage-container .fold-8 .footer-1 {
		font-family: Manrope;
		color: #272d38;
		display: flex;
		flex-direction: column;
		margin-top: 2rem;
		// gap: #272d38;
		gap: 1rem;
	}

	.homepage-container .fold-8 .footer-2 {
		display: flex;
		flex-direction: column;
		color: #272d38;
		font-family: Manrope;
		margin-bottom: 4rem;
	}

	.fold-1-2 {
		// padding: 1rem;
		margin: -3rem 0;
	}

	.social {
		display: none;
	}

	.social-2 {
		display: flex;
		gap: 1rem;
		margin-bottom: 2rem;
	}

	.homepage-container .fold-8 {
		gap: 0;

	}

	.homepage-container .fold-8 .footer-2 {
		gap: 1rem;
	}

	.footer-2 {
		margin-bottom: 4rem;
		// 4rem
	}

	.homepage-container .first-fold .homepage-hero-text-container p {
		z-index: 125;
	}

	.homepage-container .first-fold .homepage-hero-image {
		z-index: 123;
	}

	.homepage-container .fold-7 {
		margin: 5rem;
	}

	.homepage-container .asking-why-container {
		padding: 1rem;
	}

	.box-containers {
		display: none;
	}

	.box-1,
	.box-2,
	.box-3,
	.box-4,
	.box-5,
	.box-6,
	.box-7,
	.box-8,
	.box-9 {
		display: none;
	}

	.fold-5,
	.fold-4 {
		margin-bottom: 5rem;
	}

	.fold-6 {
		margin-top: 5rem;
	}

	// .homepage-container .fold-3,
	.homepage-container .fold-4,
	.homepage-container .fold-5 {
		margin-bottom: 5rem;
	}

	.fold-5 {
		padding: 2rem;
	}

	.homepage-container .asking-why-container .asking-why-text-container .asking-why-container-1 {
		width: 100%;
		// font-weight: 300;
	}

	.about-us-mobile {
		// margin-top: 2rem;
		// margin-left: auto;
		font-size: 12px;
		width: 140px;
		height: 70px;
		text-align: center;
		justify-content: center;
		font-size: 12px;
		align-items: center;
		background-position: center;
		transition: background 0.8s;
	}

	.about-us-mobile:hover {
		background: #000 radial-gradient(circle, transparent 1%, #a1d905 1%) center/15000%;
	}

	.about-us-mobile:active {
		// background-color: #a1d905;
		background-size: 100%;
		transition: background 0s;
	}

	.about-us-mobile {
		display: none;
	}

	.box-containers-what-we-do {
		display: none;
	}

	.box-containers-what-we-do {
		display: none;
	}

	.portfolio-btn {
		display: none;
	}
}

@media only screen and (max-width: 600px) {
	.about-us-btn-desktop {
		display: none;
	}

	.what-do-we-do-image {
		display: none;
	}

	.homepage-container .first-fold {
		height: 100vh;
		width: 100%;
		display: flex;
		flex-direction: row;
	}

	.homepage-container .first-fold .homepage-hero-image {
		max-width: 300px;
		max-height: 334px;
		min-height: 250px;
		min-width: 217px;
	}

	.homepage-container .fold-6 .from-why-labs {
		margin: 0;
		padding: 2rem;
		// margin-top: 5rem;
	}

	.asking-why {
		display: none;
	}

	.asking-why-2 {
		display: block;
	}

	.homepage-container .fold-5 .logo-container-homepage {
		display: none;
	}

	.logo-Mobile {
		max-width: 90%;
		height: auto;
	}

	.homepage-container .first-fold {
		display: none;
	}

	.first-fold-mobile {
		height: 100vh;
	}

	.homepage-hero-image-mobile {
		// max-width: 300px;
		height: auto;
		margin: 0 1rem;
	}

	.box-containers {
		display: none;
	}

	.box-1,
	.box-2,
	.box-3,
	.box-4,
	.box-5,
	.box-6,
	.box-7,
	.box-8,
	.box-9 {
		display: none !important;
	}

	.fold-3-mobile {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 2rem;
		// margin-bottom: 5rem;
	}

	.about-use-fold-2 {
		font-size: 12px;
	}

	.homepage-hero-text {
		font-size: 36px;
	}

	.homepage-container .fold-3 .what-do-we-do-container .box-containers {
		display: none;
	}

	.our-projects-container-1 {
		padding: 2rem 0;
	}

	.we-believe-in-letting {
		font-size: 14px;
	}

	.homepage-container .fold-5 .alongwith {
		margin: 2rem 0;
	}

	.carousel-desktop-1 {
		display: none;
	}

	.homepage-container .asking-why-container .text-bottom-container {
		// display: none;
	}
}

.asking-why {
	bottom: 150px;
}

.icons-help {
	width: 55px;
}

.icons-help-2 {
	width: 55px;
}

.bulb-chu-icon {
	width: 45px;
}

.pay10 {
	max-width: 141px;
	max-height: 50px;
}

.about-us-mobile {
	display: none;
}

@media only screen and (max-width: 400px) {
	.homepage-hero-text {
		font-size: 28px;
	}

	.homepage-hero-text-2 {
		font-size: 16px;
	}

	.homepage-hero-image-mobile {
		width: 60%;
		height: auto;
		position: relative;
		margin-left: auto;
		right: -2rem;
	}

	.homepage-container .first-fold .homepage-hero-image {
		max-width: 300px;
		max-height: 334px;
		min-height: 250px;
		min-width: 217px;
	}

	.homepage-container .fold-6 .from-why-labs {
		margin: 0;
		padding: 2rem;
		// margin-top: 5rem;
	}

	.asking-why {
		display: none;
	}

	.asking-why-2 {
		display: block;
	}

	.homepage-container .fold-5 .logo-container-homepage {
		display: none;
	}

	.logo-Mobile {
		max-width: 90%;
		height: auto;
		filter: grayscale(100%);

		img {
			filter: grayscale(100%);
		}
	}

	.homepage-container .first-fold {
		display: none;
	}

	.first-fold-mobile {
		height: 100vh;
	}

	.homepage-hero-image-mobile {}

	.about-us-mobile {
		display: none;
	}

	.what-do-we-do-image {
		display: none;
	}
}

.logo-mobile-image {
	filter: grayscale(100%);
}

.box-containers-what-we-do {
	display: flex;
	justify-content: center;
	flex-direction: column;
	// position: relative;
	align-items: center;

	.what-do-we-do {
		margin-top: 5rem;
		margin-bottom: 5rem;
		max-width: 80%;
		height: auto;
		position: relative;
	}

	.view-PortFolio-btn {
		bottom: 0;
		right: 0;
		margin-bottom: 5rem;
	}
}

.what-do-we-do-container {
	margin: 5rem 0;
}

.explore-klose:hover {}

.explore-klose {
	transition: all 0.5s ease-in-out;
}

.explore-klose:hover {
	transform: scale(1.05);
}


/*working only in ipad landscape device*/